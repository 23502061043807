<template>
    <a v-if="advertisement && advertisement.active" class="d-flex justify-content-center" :href="advertisement.url">
        <b-img fluid :src="`/api/user/v1/image/${advertisement.main_photo}`"/>
    </a>
</template>

<script>
    import {BImg} from 'bootstrap-vue'
    export default {
        components: {BImg},
        data() {
            return {
                advertisement: null
            }
        },
        methods: {
            getAds() {
                const thisIns = this
                thisIns.$http.get(`/api/user/v1/advertisement/page/${this.getPage()}/${this.isMobile()}`)
                    .then(response => {
                        thisIns.advertisement = response.data
                    })
                    // .catch(error => {
                    //     thisIns.$printError(`Pri nalaganju oglasa je prišlo do napake!\n${error.message}`)
                    // })
            },
            isMobile() {
                if (window.innerWidth <= 576) return '1'
                return '0'
            },
            getPage() {
                if (this.$route.name === 'home') return '0'
                if (this.$route.name === 'news') return '1'
                if (this.$route.name === 'real-estate') return '2'
                if (this.$route.name === 'ad') return '3'
                //if (this.$route.name === 'real-estate') return '4' //shop oglasi
                else return ''
            }
        },
        created() {
            this.getAds()
        }
    }
</script>
