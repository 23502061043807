<template>
    <b-overlay :show="showLoader">
        <b-row v-if="realEstate" style="position: relative;">
            <div class="background-fall" />
            <b-row v-if="!realEstate.active" class="w-100">
                <b-col class="w-100">
                    <b-alert variant="danger" class="p-1 text-center w-100" show><b>Ta nepremičninski oglas ni aktiven! Vidijo ga lahko samo administratorji, navadni uporabniki do njega nimajo dostopa!</b></b-alert>
                </b-col>
            </b-row>
            <b-col order="1" cols="12" md="8">
                <div class="h-100">
                    <div class="d-flex justify-content-center">
                        <b-img v-if="realEstate.main_photo != ''" :src="`/api/user/v1/image/${realEstate.main_photo}`" style="max-height:500px; width: 100%; object-fit: cover; object-position: center;"/>
                        <b-img v-else :src="require('@/assets/images/esoseska/no-image.jpg')" fluid style="max-height:500px;"/>
                    </div>
                </div>
            </b-col>


            <b-col cols="12" md="4" order-md="2" order="3" class="mt-2 mt-md-0 d-flex flex-column" style="position: relative;">
                <div class="position-relative">
                    <div class="badge">{{ setListingType(realEstate.listing_type) }}</div>
                </div>
                <h3 class="title ">{{ realEstate.title }}</h3>
                <h5 class="pb-1 border-side mt-1 mb-1"><span class="font-weight-light">{{ realEstate.location }}</span></h5>
                <div>
                    <h3 class="font d-flex">
                        <div class="inner-text">
                            Lokacija:
                        </div>
                        <div>
                            <span class="font-weight-light">{{ realEstate.location }}</span>
                        </div>
                    </h3>
                    <h3 class="font d-flex">
                        <div class="inner-text">
                            Velikost:
                        </div>
                        <span class="font-weight-light">{{ realEstate.size }} m<sup>2</sup></span>
                    </h3>

                    <h3 class="font d-flex">
                        <div class="inner-text">
                            Zgrajeno:
                        </div>
                        <span class="font-weight-light">{{ realEstate.build }}</span>
                    </h3>
                    <h3 class="font d-flex">
                        <div class="inner-text">
                            Prenovljeno:
                        </div>
                        <span class="font-weight-light">{{ realEstate.renovated }}</span>
                    </h3>
                    <h3 class="font d-flex">
                        <div class="inner-text">
                            Etažnost:
                        </div>
                        <span class="font-weight-light">{{ realEstate.floor }}</span>
                    </h3>
                </div>
                <div v-if="getProvider(realEstate.provider) !== ''" class="mt-2 mt-lg-auto font">
                    <span>Ponudnik:</span>
                    <span class="ml-1 font-weight-light">{{getProvider(realEstate.provider)}}</span>
                </div>
            </b-col>
            <b-col order-md="3" order="2" cols="12" md="8" >
                <div v-if="photos && photos.length > 0">
                    <swiper :swiper-data="photos" :pagination="false" type="imageGallery"/>
                </div>
            </b-col>
            <b-col order="4" cols="12" md="4" class="mt-1">
                <div style="background: #F3F3F3; height: 100%" class="p-1">
                    <div>
                        <h5>Cena:</h5>
                        <div>
                            <h1 v-if="realEstate.price_offer" class="mb-0 font">PO DOGOVORU</h1>
                            <h1 v-else-if="realEstate.price_per" class="mb-0 price-font">{{$convertPriceIntegerToFloat(realEstate.price)}} €/m<sup>2</sup></h1>
                            <h1 v-else class="mb-0 price-font">{{$convertPriceIntegerToFloat(realEstate.price)}} €</h1>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row v-if="realEstate !== null" class="mt-3">
            <b-col cols="12" md="6">
                <div class="d-flex align-items-start flex-column h-100">
                    <div class="mb-1">
                        <div style="font-weight:bold;">
                            Celoten opis objekta
                        </div>
                    </div>
                    <div class="html-display mb-2" v-html="realEstate.description" />

                    <!-- DELITEV NEPREMICNINE NA FB -->
                    <div class="mt-auto pb-1" :data-href="currentUrl">
                        <b-button pill class="share-button mb-1 d-flex align-items-center" :href="'https://www.facebook.com/sharer/sharer.php?u=' + currentUrl + '&&src=sdkpreparse'">
                            <b-img class="mr-1" height="15px" :src="require('@/assets/images/icons/Share.svg')" />
                            Deli nepremičnino
                        </b-button>
                    </div>
                </div>
              
            </b-col>
            <b-col cols="12" md="6">
                <Contact id="contact-form" class="" :contactData="agent" />
            </b-col>
        </b-row>
        <advertisement-card/>

        <!-- PREDSTAVITEV SOSESKE -->
        <div style="position: relative;" v-if="neighborhoodItem && neighborhoodItem.description">
            <div class="soseska-intro">PREDSTAVITEV SOSESKE</div>
            <div class="soseska-light-background" />
            <div class="row">
                <div class="col-4 soseska-background p-2 d-flex align-items-start flex-column">
                    <div class="soseska-title">
                        {{neighborhoodItem.title}}
                    </div>
                    <div class="soseska-desc mt-1" v-html="neighborhoodItem.description.replace(/<(?!br\s*\/?)[^>]+>/g, '')">
                    </div>
                    <div class="d-flex align-items-end justify-content-between mt-auto w-100">
                        <b-button @click="$router.push({name: 'neighborhood', params: { real_estate_id: realEstateID  }})" pill class="button-soseska">
                            Več o tem
                        </b-button>
                        <img :src="require('@/assets/images/icons/Map.svg')" style="max-width: 40%; max-height: 60px;"/>
                    </div>
                </div>
                <div class="col-8">
                    <b-img v-if="neighborhoodItem.main_photo != ''" :src="`/api/user/v1/image/${neighborhoodItem.main_photo}`" style="height: 300px; max-height:300px; width: 100%; object-fit: cover; object-position: center;" />
                    <b-img v-else :src="require('@/assets/images/esoseska/no-image.jpg')" fluid style="max-height:500px;"/>
                </div>
            </div>
        </div>

        <div style="position: relative;" v-if="neighborhoodItem && !neighborhoodItem.description">
            <div class="soseska-intro mb-3">PREDSTAVITEV SOSESKE</div>
            <div class="soseska-light-background2" />

            <div class="row mb-5">
                <div class="col-4 soseska-background p-2 d-flex align-items-start flex-column" style="height: 300px; max-height: 300px;">
                    <div class="soseska-title">
                        {{neighborhoodItem.title}}
                    </div>
                 
                    <div class="d-flex align-items-end justify-content-start mt-auto w-100">
                        <img :src="require('@/assets/images/icons/Map.svg')" style="max-width: 40%; max-height: 60px;"/>
                    </div>
                </div>
                <div class="col-8">
                    <div v-for="(item, id) of neighborhoodItem.description_list" :key="'Seznam__' + id">
                        <div>
                            <div class="title-description-list mb-2">
                                + {{item.title}}
                            </div>
                            <div class="content-description-list mb-2">
                                {{item.description}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <b-row class="mt-2" >
            
            
                <b-col cols="12" md="10" class="pl-0">
                    <div class="d-flex justify-content-center">
                        <b-img v-if="neighborhoodItem.main_photo != ''" :src="`/api/user/v1/image/${neighborhoodItem.main_photo}`" style="max-height:500px; width: 100%; object-fit: cover; object-position: center;"/>
                        <b-img v-else :src="require('@/assets/images/esoseska/no-image.jpg')" fluid style="max-height:500px;"/>
                    </div>
                </b-col>

                <b-col cols="12" md="2" class="pl-0">
                    <div v-if="photos && photos.length > 0">
                        <swiper :swiper-data="photos_neighborhood" :pagination="false" type="imageGalleryVertical"/>
                    </div>
                </b-col>
            </b-row>

        </div>

        
        <scroll-to-top  />
    </b-overlay>
</template>
<script>
    import Swiper from '../../Components/Swiper'
    import Contact from '../../Components/Contact'
    import AdvertisementCard from '../../Components/AdvertisementCard'
    import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
    import {BOverlay, BRow, BCol, BAlert, BImg, BButton} from 'bootstrap-vue'

    export default {
        components: {
            Swiper,
            Contact,
            AdvertisementCard,
            ScrollToTop,
            BOverlay,
            BRow,
            BCol,
            BAlert,
            BImg,
            BButton
            // BCard,
            // BCardText,
            // BButton
        },
        data() {
            return {
                neighborhoodItem: null,
                realEstateID: 0,
                showLoader: false,
                realEstate: null,
                lastRealEstates: [],
                agent: null,
                photos: [],
                photos_neighborhood: [],
                currentUrl: ''
            }
        },
        methods: {
            scrollToContactForm() {
                this.$scrollToElement(
                    document.getElementById('contact-form')
                )
            },
            selectImage(item) {
                this.realEstate.main_photo = item
            },
            setBreadcrumbTitle(title) {
                const breadCrumbIndex = this.$route.meta.breadcrumb.findIndex(x => x.isCustomBreadcrumb)
                
                if (breadCrumbIndex !== -1) {
                    this.$route.meta.breadcrumb[breadCrumbIndex].text = title

                    this.$root.$emit('force-update-breadcrumbs')
                } 
            },
            async loadData() {
                const thisIns = this
                thisIns.showLoader = true
                this.photos = []

                this.$scrollToTop()
                try {
                    const realEstateResponse = await thisIns.$http.get(`/api/user/v1/real_estate/${this.realEstateID}`)
                    this.realEstate = realEstateResponse.data

                    for (const item of this.realEstate.additional_photos) {
                        this.photos.push({itemId: `${item}_idSlike`, src: `/api/user/v1/image/${  item}`, thumbnail: `/api/user/v1/image/thumbnail_${  item}`, thumbnailHeight: '100px'})
                    }

                    this.setBreadcrumbTitle(this.realEstate.title)
                    this.agent = this.realEstate.agent

                } catch (error) {
                    thisIns.$printError(`Pri nalaganju oglasov je prišlo do napake!\n${error.message}`)
                    thisIns.showLoader = false
                } finally {
                    if (this.realEstate && this.realEstate.neighborhood_id && this.realEstate.neighborhood_id !== this.$NILObjectID) this.loadNeighborhood()
                    
                    thisIns.showLoader = false
                }
                   
            },
            async loadNeighborhood() {
                try {
                    const res = await this.$http.get(`/api/user/v1/neighborhoods/${this.realEstateID}`)
                    this.neighborhoodItem = res.data

                    for (const item of this.neighborhoodItem.additional_photos) {
                        this.photos_neighborhood.push({itemId: `${item}_idSlike`, src: `/api/user/v1/image/${  item}`, thumbnail: `/api/user/v1/image/thumbnail_${  item}`, thumbnailHeight: '100px'})
                    }


                } catch (error) {
                    this.$printError(`Pri nalaganju soseske je prišlo do napake!\n${error.message}`)
                }
            },
            getLastRealEstates() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get('/api/user/v1/es/real_estates/?&page=1&&perPage=6&')
                    .then(res => {
                        const realEstates = res.data.items ?? []
                        if (realEstates && realEstates.length > 0) {
                            const currentRealEstateIndex = realEstates.findIndex(r => r.id === this.realEstate.id)
                            if (currentRealEstateIndex !== -1) realEstates.splice(currentRealEstateIndex, 1)
                            thisIns.lastRealEstates = realEstates
                        }
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju nepremičnin je prišlo do napake!\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            stripHTML(val) {
                if (!val) return ''
                const text = val
                if (text.length > 850) return `${text.substring(0, 850)}...`
                else return text
            },
            goto(id) {
                const element = document.getElementById(id)
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            },
            getProvider(provider) {
                return this.$store.getters['app/getRealEstateProvider'](provider)
            },
            setListingType(type) {
                if (type === 0) return 'PRODAJA'
                else if (type === 1) return 'ODDAJA'
                else if (type === 2) return 'NAJEM'
                else if (type === 3) return 'ZAMENJAVA'
                else return ''
            }
        },
        mounted() {
            this.currentUrl = window.location.href
            this.loadData()
          
            // this.getLastRealEstates()
        },
        created() {
            this.realEstateID = this.$route.params.real_estate_id
            this.$root.$refs.RealEstate = this
        },
        watch: {
            // if route stays the same and only params get updates (ex. when watching real_estate you click on other real estate in swiper),
            // component is not destroyed, so created() and mounted() will not be called
            // here we are watching for that parameter, if it changes, let's reload all data
            '$route.params.real_estate_id'() {
                this.realEstateID = this.$route.params.real_estate_id
                this.$root.$refs.RealEstate = this

                this.$scrollToTop()

                this.loadData()
                // this.getLastRealEstates()
            }
        }
    }
</script>

<style scoped>
     .title-description-list{
        font-size: 1.5rem;
        font-weight: 900;
        margin-bottom: 10px;
    }

    .soseska-light-background{
        position: absolute;
        top: 50px;
        height: 150px;
        width: 9999px;
        margin-left: -2000px !important;
        margin-right: -2000px !important;
        background: linear-gradient(#72a5d84b, white) !important;
        z-index: -1;
    }
    .soseska-light-background2{
        position: absolute;
        top: 35px;
        height: 150px;
        width: 9999px;
        margin-left: -2000px !important;
        margin-right: -2000px !important;
        background: linear-gradient(#72a5d84b, white) !important;
        z-index: -1;
    }
    .soseska-intro{
        color: rgb(160, 160, 160);
        margin-bottom: 10px;
        margin-left: -13px;
    }
    .soseska-title{
        font-size: 1.5rem;
        font-weight: bold;
        color: white;
    }

    .soseska-background{
        background: #72a5d8;
    }

    .soseska-desc{
         display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .button-soseska{
        background: #FFFFFF !important;
        color: #72a5d8 !important;
        padding: 8px 20px 8px 20px;
    }
.share-button{
    border: none !important;
    background: #E7E6E6 !important;
    color: black !important;
    font-size: 12px;
    padding: 7px 10px 7px 10px !important;
    box-shadow: none !important;
}
.share-button:hover, .share-button:active, .share-button:focus{
    box-shadow: none !important;
    background: #E7E6E6 !important;
    border: none !important;
}
.price-font{
    font-weight: bold;
    font-size: 2.8rem !important;
}
.inner-text{
    min-width: 200px;
    max-width: 200px;
    width: 200px;
}
.border-side{
    border-bottom: 2px  solid grey;
}
 .badge {
    /* position: absolute; */
    background: #72a5d8;
    /* left: 0px;
    top: -15px; */
    margin-bottom: 35px;
    font-size: 1.5rem;
    padding-right: 24px;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 93% 100%, 100% 0);
    border-radius: 0px !important;
  }
.background-fall{
    position: absolute;
    height: 150px;
    width: 9999px !important;
    top: 50px;
    left: -2000px;
    background: linear-gradient(#72a5d84b, white) !important;
}

.realEstateSwiperTitle {
    font-size: 36px;
    font-weight: 900;
}
.realestateCard {
  border: 1px solid #C4C4C4 !important;
  border-radius:0;
  box-shadow: none;
}
.realestateCard .card-footer {
  border-top: none !important;
  padding: 0 1.5rem 1.5rem 1.5rem !important;
}
.realEstateTabs ul li .active{
  color: #009FD4 !important;
}
p a {
  color: #009FD4 !important;
  font-weight: bold;
}
.realEstateTabs ul li a:hover{
  color: #009FD4 !important;
}
.realEstateTabs .nav-tabs  {
  border-bottom: 5px solid #E6E6E6;
  font-size: 18px;
}
.realEstateTabs .nav-tabs .nav-link:after {
  background: none !important;
}
.font {
font-size: 18px;
  font-weight: light;

}
.realestateCard .card-body > h4.card-title {
  font-weight: bold;
}
.all-page {
  width: 100vw;
  height: 100%;
  position: relative;
  left: 50%;
  margin-left: -50vw;
}
.title {
    font-size: 1.8em;
    text-transform: uppercase;
    font-weight: 900;
}
</style>

<style>
.real-estate-description > * {
  text-align: justify !important;
  margin: 0 !important;
}
</style>